import { PropsWithChildren } from 'react'
import { Helmet } from 'react-helmet'
import { Footer } from './Footer'
import { Header } from './Header'
import { useCookiesConfig } from '@/features/cookies/use-cookies'
import { CookieBanner } from '@/features/cookies/CookieBanner'

// Images
import imgOG from '@/assets/images/og/ogimage.png'

export const Layout = ({
  title,
  description,
  children,

  disableAnalytics = false,
}: PropsWithChildren<{
  title: string
  description: string
  disableAnalytics?: boolean
}>): JSX.Element => {
  const cookiesConfig = useCookiesConfig()

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name='description' content={description} />
        <meta
          name='robots'
          content='index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1'
        />
        <link rel='canonical' href='https://trazable.io/' />
        <meta name='facebook-domain-verification' content='gdt37htl4djqwmarnkdyct76lx1zos' />

        {/* Og Meta */}
        <meta property='og:locale' content='es_ES' />
        <meta property='og:type' content='website' />
        <meta property='og:title' content={title} />
        <meta property='og:description' content={description} />
        <meta property='og:url' content='https://trazable.io/' />
        <meta property='og:site_name' content='Trazable' />
        <meta property='article:publisher' content='https://www.facebook.com/trazable/' />
        <meta property='article:modified_time' content='2021-04-09T11:41:00+00:00' />
        <meta property='og:image' content={imgOG} />
        <meta property='og:image:width' content='1200' />
        <meta property='og:image:height' content='630' />
        {/* End OG Meta */}

        {/* Twitter Meta */}
        <meta name='twitter:card' content='summary_large_image' />
        <meta name='twitter:site' content='@iotrazable' />
        <meta name='twitter:label1' content='Tiempo de lectura' />
        <meta name='twitter:data1' content='3 minutos' />
        {/* End Twitter Meta */}

        <script type='application/ld+json' className='yoast-schema-graph'>{`
          {"@context":"https://schema.org","@graph":[{"@type":"WebSite","@id":"https://trazable.io/#website","url":"https://trazable.io/","name":"Trazable","description":"Trazabilidad end-to-end de alimentos","potentialAction":[{"@type":"SearchAction","target":"https://trazable.io/?s={search_term_string}","query-input":"required name=search_term_string"}],"inLanguage":"es"},{"@type":"ImageObject","@id":"https://trazable.io/#primaryimage","inLanguage":"es","url":"https://trazable.io/assets/images/icono-trazable-150.png","width":150,"height":150,"caption":"trazable logo150 farm to fork blockchain traceability"},{"@type":"WebPage","@id":"https://trazable.io/#webpage","url":"https://trazable.io/","name":"Trazable - Trazabilidad end-to-end de alimentos","isPartOf":{"@id":"https://trazable.io/#website"},"primaryImageOfPage":{"@id":"https://trazable.io/#primaryimage"},"datePublished":"2019-01-22T08:16:34+00:00","dateModified":"2021-04-09T11:41:00+00:00","description":"Optimiza tu cadena de suministro con informaci\u00f3n a tiempo real de toda la cadena, gracias a nuestra plataforma blockchain","inLanguage":"es","potentialAction":[{"@type":"ReadAction","target":["https://trazable.io/"]}]}]}
        `}</script>

        {/* Google Tag Manager */}
        {cookiesConfig?.isConfigured && cookiesConfig.config?.analytics && !disableAnalytics && (
          <script>{`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-T432GJH');
        `}</script>
        )}
        {/* End Google Tag Manager */}

        {/* Disallow cache */}
        <meta http-equiv='cache-control' content='max-age=0' />
        <meta http-equiv='cache-control' content='no-cache' />
        <meta http-equiv='expires' content='0' />
        <meta http-equiv='expires' content='Tue, 01 Jan 1980 1:00:00 GMT' />
        <meta http-equiv='pragma' content='no-cache' />
      </Helmet>

      <Header />
      <main>{children}</main>
      <Footer />

      <CookieBanner />
    </>
  )
}
