import { Link } from '@/components/Link'
import { Layout } from '@/layout/Layout'
import { PAGES } from '@/routes'
import { PageProps } from 'gatsby'
import { useTranslation } from 'react-i18next'
import pdfWhitepaperES from '@/assets/whitepaper/whitepaper_trz_v1.1_es.pdf'
import pdfWhitepaperEN from '@/assets/whitepaper/whitepaper_trz_v1.1_en.pdf'
import { useMemo } from 'react'

const TermsAndConditionsPage = ({
  pageContext,
}: PageProps<never, { language: string }>): JSX.Element => {
  const { t, i18n } = useTranslation('nsTermsAndConditions')

  if (i18n.language !== pageContext.language) {
    i18n.changeLanguage(pageContext.language)
  }

  const pdfWhitepaperByLang = useMemo(() => {
    if (i18n.language.startsWith('es')) {
      return pdfWhitepaperES
    } else {
      return pdfWhitepaperEN
    }
  }, [i18n.language])

  return (
    <Layout title={t('meta.title')} description={t('meta.description')}>
      {i18n.language.startsWith('es') ? (
        // Spanish Translation
        <>
          <div className='relative bg-white overflow-hidden z-0'>
            <div className='max-w-7xl mx-auto'>
              <div className='relative z-10 pt-8 pb-8 bg-white sm:pb-16 md:pb-20 lg:w-full lg:pb-28 xl:pb-0'>
                <main className='mx-auto px-4 sm:px-6 lg:px-8 sm:pt-24'>
                  <div className='text-center'>
                    <h1 className='text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl'>
                      <span className='block xl:inline'>
                        TÉRMINOS Y CONDICIONES DEL SERVICIO Y OFERTA PÚBLICA TOKENS
                      </span>
                    </h1>
                  </div>
                </main>
              </div>
            </div>
          </div>
          <div className='relative py-16 bg-white overflow-hidden'>
            <div className='hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full'>
              <div className='relative h-full text-lg max-w-prose mx-auto' aria-hidden='true'>
                <svg
                  className='absolute top-12 left-full transform translate-x-32'
                  width='404'
                  height='384'
                  fill='none'
                  viewBox='0 0 404 384'
                >
                  <defs>
                    <pattern
                      id='74b3fd99-0a6f-4271-bef2-e80eeafdf357'
                      x='0'
                      y='0'
                      width='20'
                      height='20'
                      patternUnits='userSpaceOnUse'
                    >
                      <rect
                        x='0'
                        y='0'
                        width='4'
                        height='4'
                        className='text-gray-200'
                        fill='currentColor'
                      />
                    </pattern>
                  </defs>
                  <rect
                    width='404'
                    height='384'
                    fill='url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)'
                  />
                </svg>
                <svg
                  className='absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32'
                  width='404'
                  height='384'
                  fill='none'
                  viewBox='0 0 404 384'
                >
                  <defs>
                    <pattern
                      id='f210dbf6-a58d-4871-961e-36d5016a0f49'
                      x='0'
                      y='0'
                      width='20'
                      height='20'
                      patternUnits='userSpaceOnUse'
                    >
                      <rect
                        x='0'
                        y='0'
                        width='4'
                        height='4'
                        className='text-gray-200'
                        fill='currentColor'
                      />
                    </pattern>
                  </defs>
                  <rect
                    width='404'
                    height='384'
                    fill='url(#f210dbf6-a58d-4871-961e-36d5016a0f49)'
                  />
                </svg>
                <svg
                  className='absolute bottom-12 left-full transform translate-x-32'
                  width='404'
                  height='384'
                  fill='none'
                  viewBox='0 0 404 384'
                >
                  <defs>
                    <pattern
                      id='d3eb07ae-5182-43e6-857d-35c643af9034'
                      x='0'
                      y='0'
                      width='20'
                      height='20'
                      patternUnits='userSpaceOnUse'
                    >
                      <rect
                        x='0'
                        y='0'
                        width='4'
                        height='4'
                        className='text-gray-200'
                        fill='currentColor'
                      />
                    </pattern>
                  </defs>
                  <rect
                    width='404'
                    height='384'
                    fill='url(#d3eb07ae-5182-43e6-857d-35c643af9034)'
                  />
                </svg>
              </div>
            </div>
            <div className='relative px-4 sm:px-6 lg:px-8'>
              <div className='text-lg max-w-prose mx-auto'>
                <h1>
                  <span className='mt-12 block text-3xl text-center leading-8 font-extrabold uppercase tracking-tight text-gray-900 sm:text-4xl'>
                    DEFINICIONES
                  </span>
                </h1>
                <ul className='list-disc list-inside pl-4 mt-2'>
                  <li>
                    <b> TRAZABLE</b>: Blockchain traceability
                  </li>
                  <li>
                    <b>Blockchain</b>: la red pública descentralizada y distribuida que TRAZABLE
                    utiliza para la correcta prestación del Servicio.
                  </li>
                  <li>
                    <b>Plataforma</b>: sitio web [https://trazable.io] mediante la cual el Usuario
                    accede a los Servicios.
                  </li>
                </ul>
                <p className='mt-8 text-xl text-gray-500 leading-8'>Servicio/Servicios:</p>
                <ul className='list-disc list-inside pl-4 mt-2 mb-12'>
                  <li>
                    <b>Wallet</b>: conjunto de elementos criptográficos que permiten la custodia del
                    balance de los Criptoactivos depositados en el mismo por un único Usuario.
                  </li>
                  <li>
                    <b>Criptoactivos o token</b>: activos digitales o monedas digitales virtuales
                    emitidas a través de protocolos informáticos y no respaldadas por una entidad o
                    Banco Central. A los efectos de los presentes Términos y Condiciones se
                    considerarán Criptoactivos el token TRZ de Trazable.
                  </li>
                  <li>
                    <b>Usuario</b>: persona física registrada en la Plataforma que accede a los
                    Servicios.
                  </li>
                  <li>
                    <b>KYC/AML</b>: procedimiento “know your customer” cuyo objetivo es la
                    identificación de las personas físicas y jurídicas para el cumplimiento de la
                    normativa de prevención de blanqueo de capitales, y concretamente, la Ley
                    10/2010, de 28 de abril, de prevención del blanqueo de capitales y de la
                    financiación del terrorismo, así como la normativa que la desarrolla , tal y
                    como han sido modificadas por el Real Decreto-ley 7/2021, de 27 de abril, de
                    transposición de directivas de la Unión Europea en las materias de competencia,
                    prevención del blanqueo de capitales, entidades de crédito, telecomunicaciones,
                    medidas tributarias, prevención y reparación de daños medioambientales,
                    desplazamiento de trabajadores en la prestación de servicios transnacionales y
                    defensa de los consumidores.
                  </li>
                </ul>

                <ol className='list-bold list-decimal list-outside mt-12 text-xl text-gray-500 leading-8'>
                  {/* 1 */}
                  <li>
                    <span className='font-bold uppercase text-2xl'>INTRODUCCIÓN</span>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      Bienvenido a la aplicación web TRAZABLE (la "Plataforma", o "nosotros"
                      conjuntamente). A continuación, detallaremos el acuerdo de licencia que regula
                      los términos en que usted, como Usuario, puede usar la Plataforma y los
                      Servicios que en ella se ofrecen.
                    </p>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      El presente documento (conjuntamente con todos los documentos en él
                      mencionados) establece las condiciones por las que se rige la venta pública de
                      tokens de esta Plataforma (y el uso del Servicio proporcionado a través de la
                      misma (en adelante, los "Términos y Condiciones").
                    </p>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      Rogamos al Usuario que lea atentamente las presentes Términos y Condiciones y
                      nuestra Política de Privacidad y Cookies antes de usar la Plataforma.
                    </p>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      Al utilizar el servicio prestado en esta Plataforma el Usuario consiente
                      quedar vinculado por los Términos y Condiciones, así como por nuestra{' '}
                      <Link className='underline, text-blue-500' to={PAGES.PRIVACY_POLICY}>
                        Política de Privacidad
                      </Link>{' '}
                      y{' '}
                      <Link className='underline, text-blue-500' to={PAGES.COOKIES}>
                        Cookies
                      </Link>
                      , por lo que, si el Usuario no está de acuerdo con todas las estipulaciones de
                      los Términos y Condiciones y con la Política de Privacidad y Cookies, no debe
                      usar esta Plataforma.
                    </p>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      Si el Usuario tiene alguna pregunta relacionada con las condiciones o la
                      Política de Privacidad y Cookies puede ponerse en contacto con nosotros a
                      través de nuestras vías de contacto.
                    </p>
                  </li>

                  {/* 2 */}
                  <li>
                    <span className='font-bold uppercase text-2xl'>INFORMACIÓN LEGAL</span>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      En cumplimiento con el deber de información recogido en artículo 10 de la Ley
                      34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y del
                      Comercio Electrónico, a continuación, se reflejan los siguientes datos del
                      titular:
                    </p>
                    <ul className='list-disc list-inside pl-4 mt-2 mb-12'>
                      <li>Nombre: BLOCKCHAIN TRACEABILITY, S.L.</li>
                      <li>NIF: B-40547507</li>
                      <li>Domicilio social: Avd/Amado Granell Mesado 65, 46013 Valencia</li>
                    </ul>
                  </li>

                  {/* 3 */}
                  <li>
                    <span className='font-bold uppercase text-2xl'>USUARIOS y consentimiento</span>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      El uso de la Plataforma atribuye la condición de usuario, que acepta, desde el
                      acceso, descarga y/o uso de la misma, los Términos y Condiciones aquí
                      reflejados.
                    </p>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      Asimismo, la adquisición de tokens de la Plataforma implica la aceptación de
                      los Términos y Condiciones aquí reflejados.
                    </p>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      Para hacer uso de los Servicios ofrecidos en la Plataforma, el Usuario debe
                      ser mayor de 18 años.
                    </p>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      La Plataforma permitirá al Usuario acceder a los Servicios especificados en
                      los presentes Términos y Condiciones.
                    </p>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      Antes de hacer uso de los Servicios, el usuario debe tener en cuenta que el
                      riesgo de pérdida en la compraventa o tenencia de Criptoactivos puede ser
                      considerable. Como ocurre con cualquier activo, el valor de los Criptoactivos
                      puede variar considerablemente y existe un riesgo sustancial de que pierda
                      dinero comprando, vendiendo, manteniendo o invirtiendo en Criptoactivos. Por
                      tanto, al utilizar la Plataforma, el Usuario reconoce y acepta los riesgos que
                      supone comprar, vender, mantener o invertir en Criptoactivos.
                    </p>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      Asimismo, al utilizar la Plataforma el Usuario acepta que TRAZABLE no
                      proporciona ningún tipo de consejo de inversión en relación a los
                      Criptoactivos que se pueden adquirir a través de la Plataforma, y en ningún
                      caso constituirá asesoría financiera o de inversión y no se interpretará por
                      el Usuario como tal. Cualquier decisión de compra o venta de Criptoactivos es
                      decisión exclusiva del Usuario, y TRAZABLE no será en ningún caso responsable
                      por ninguna pérdida sufrida como consecuencia de esa decisión.
                    </p>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      Los servicios de Criptoactivos no están regulados actualmente por las
                      autoridades financieras ni quedan bajo el paraguas de protección que pudieran
                      ofrecer los esquemas de compensación de servicios financieros en España, por
                      tanto, el Usuario debe considerar cuidadosamente si operar o mantener
                      Criptoactivos es adecuado.
                    </p>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      Asimismo, mediante la aceptación de los presentes términos y condiciones,{' '}
                      <b>el Usuario reconoce y acepta que</b>:
                      <ul className='list-disc list-inside pl-4 mt-2 mb-12'>
                        <li>Los precios de los Criptoactivos son extremadamente volátiles.</li>
                        <li>
                          El Usuario es el único responsable de determinar qué impuestos se aplican
                          a sus transacciones de Criptoactivos y a cumplir sus obligaciones
                          tributarias.
                        </li>
                      </ul>
                    </p>
                  </li>

                  {/* 4 */}
                  <li>
                    <span className='font-bold uppercase text-2xl'>
                      OFERTA PÚBLICA DE TOKENS USO DE LA PLATAFORMA
                    </span>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      La Oferta Pública está constituida por la generación, mediante tecnología
                      Blockchain, de los Tokens criptográficos mencionados en el apartado de
                      definiciones de los presentes Términos y Condiciones que se pondrán en
                      disposición del Usuario, que podrá adquirirlos a un precio predeterminado, a
                      cambio de dinero fiduciario.
                    </p>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      TRZ es el token que emitirá el Emisor, como token no representativo de valor
                      negociable (utility token).
                    </p>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      El token se ejecutará en la cadena de bloques de la red de Ethereum siguiendo
                      el estándar ERC-20 así como en la Binance Smart Chain siguiendo el estándar
                      BEP-20.
                    </p>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      Las características de la Oferta Pública son las siguientes:
                      <ul className='list-disc list-inside pl-4 mt-2 mb-12'>
                        <li>
                          Fase 1:
                          <ul className='list-circle list-inside pl-4 mt-2 mb-12'>
                            <li>Comienza el 6 de Diciembre de 2021</li>
                            <li>Finaliza el 12 de Diciembre de 2021</li>
                            <li>
                              Periodo de Lock-up de 3 meses desde el final de la oferta pública
                            </li>
                            <li>
                              Periodo de vesting de 3 meses desde el final del periodo de Lock-up
                            </li>
                            <li>Compra mínima de 500 €</li>
                            <li>Compra máxima de 50.000 €</li>
                            <li>Número máximo de tokens expedidos 75.000.000</li>
                            <li>Precio por unidad de 0.015€</li>
                          </ul>
                        </li>
                        <li>
                          Fase 2:
                          <ul className='list-circle list-inside pl-4 mt-2 mb-12'>
                            <li>Comienza el 13 de Diciembre de 2021</li>
                            <li>Finaliza el 19 de Diciembre de 2021</li>
                            <li>
                              Periodo de Lock-up de 1 meses desde el final de la oferta pública
                            </li>
                            <li>
                              Periodo de vesting de 1 meses desde el final del periodo de Lock-up
                            </li>
                            <li>Compra mínima de 100 €</li>
                            <li>Compra máxima de 10.000 €</li>
                            <li>Número máximo de tokens expedidos 75.000.000</li>
                            <li>Precio por unidad de 0.02€</li>
                          </ul>
                        </li>
                      </ul>
                    </p>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      El Usuario acepta conocer que los Tokens están sometidos a un periodo de
                      Vesting y de bloqueo (periodo de Lock up) en función de los cuales la cantidad
                      de Tokens adquiridos están sometidos a un periodo de bloqueo y de una puesta a
                      disposición gradual (periodo de Vesting).
                    </p>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      La liberación de Tokens dentro de este Periodo de Vesting será de 1 mes o 3
                      meses (dependiendo de la fase de adquisición), desde la finalización del
                      periodo de Lock up (es decir, 1 o 3 meses desde la finalización de la Oferta
                      Pública).
                    </p>
                  </li>

                  {/* 5 */}
                  <li>
                    <span className='font-bold uppercase text-2xl'>
                      WALLET E IDENTIFICACIÓN DEL USUARIO
                    </span>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      El Usuario deberá facilitar el Wallet en el cual le deberán ser entregados los
                      Tokens. En caso de que el Usuario no facilite la dirección de Wallet en el
                      tiempo y forma solicitados, TRAZABLE decidirá unilateralmente (I) si proceder
                      a la devolución de la inversión proporcionada por el usuario; o (ii) enviar
                      los tokens una vez el usuario proporcione su clave. En el caso (II), el
                      usuario asume que los tokens no serán entregados en el plazo acordado, dada la
                      falta de información por parte de TRAZABLE para cumplir el mismo y TRAZABLE
                      decidirá el nuevo plazo de entrega.
                    </p>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      Sólo podrá facilitarse un Wallet por Usuario.
                    </p>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      En caso de que el usuario modifique su Wallet, deberá comunicar realizar dicho
                      cambio antes de que finalice el periodo de vesting en la plataforma de
                      Trazable.
                    </p>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      Para proceder a la compra de Token se requerirá al Usuario que aporte aquellos
                      datos personales necesarios para su efectiva identificación (KYC), así como la
                      documentación correspondiente que lo acredite, a efectos de prevenir toda
                      potencial conducta de blanqueo de capitales y financiación del terrorismo y
                      poder adoptar medidas adecuadas de diligencia debida. El Usuario se obliga de
                      forma irrevocable a garantizar que la información aportada es veraz. Del mismo
                      modo, el Usuario deberá cumplimentar las declaraciones responsables, que en
                      cada caso le sean solicitadas.
                    </p>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      TRAZABLE no se hace responsable en caso de que el Usuario indique una
                      dirección de Wallet errónea o bien, si modifica la dirección de la misma y no
                      lo notifica a Trazable conforme se indica en los presentes términos y
                      condiciones.
                    </p>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      Trazable únicamente recomienda Metamask como wallet para la recepción de los
                      tokens. Queda bajo responsabilidad del usuario el uso de otra wallet que no
                      sea la recomendada por Trazable, así como la pérdida de tokens debido al uso
                      de otra wallet que no sea la recomendada.
                    </p>
                  </li>

                  {/* 6 */}
                  <li>
                    <span className='font-bold uppercase text-2xl'>UTILIDADES DEL TOKEN</span>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      Las funcionalidades del token son las que se especifican en el{' '}
                      <a
                        href={pdfWhitepaperByLang}
                        target='_blank'
                        className='underline text-blue-500'
                        rel='noreferrer'
                      >
                        Whitepaper
                      </a>
                      .
                    </p>
                  </li>

                  {/* 7 */}
                  <li>
                    <span className='font-bold uppercase text-2xl'>GENERALIDADES</span>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      TRAZABLE ofrece a través de la Plataforma los Servicios especificados en las
                      presentes Términos y Condiciones.
                    </p>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      El Usuario asume la responsabilidad del uso de la Plataforma. Dicha
                      responsabilidad se extiende al registro que fuese necesario para acceder a los
                      Servicios.{' '}
                    </p>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      En el proceso de registro se requerirá al Usuario que aporte aquellos datos
                      personales necesarios para su efectiva identificación, así como la
                      documentación correspondiente que lo acredite, a efectos de prevenir toda
                      potencial conducta de blanqueo de capitales y financiación del terrorismo y
                      poder adoptar medidas adecuadas de diligencia debida. El Usuario se obliga de
                      forma irrevocable a garantizar que la información aportada es veraz. Del mismo
                      modo, el Usuario deberá cumplimentar las declaraciones responsables, que en
                      cada caso le sean solicitadas.
                    </p>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      TRAZABLE se reserva el derecho de solicitar la actualización de la información
                      si tiene constancia de que ha podido sufrir alguna modificación. Del mismo
                      modo, TRAZABLE se reserva el derecho a realizar las comprobaciones oportunas a
                      efectos de confirmar el origen legal de las aportaciones de fondos que se
                      realicen. En caso de tener constancia de irregularidades en el proceso de
                      registro y en el posterior uso de la Plataforma por el Usuario, TRAZABLE
                      procederá a tomar acciones a los efectos de prevenir toda conducta de
                      prevención del blanqueo de capitales y financiación del terrorismo. Como
                      consecuencia de este registro, al Usuario se proporcionará una contraseña, de
                      la que será responsable, comprometiéndose a hacer un uso diligente y
                      confidencial de la misma.{' '}
                    </p>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      En caso de acceso no autorizado en la cuenta del Usuario, este se compromete a
                      notificarlo de forma inmediata a TRAZABLE a través de los medios de contacto
                      puestos a disposición del Usuario e indicados en los presentes Términos y
                      Condiciones.
                    </p>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      El proceso de registro también puede llegar a efectuarse ingresando en la
                      Plataforma a través de las siguientes redes sociales que sean utilizadas por
                      el Usuario: Instagram, LinkedIn, Telgeram y Twitter. En esos casos, el Usuario
                      acepta que TRAZABLE obtenga de estos terceros determinados datos personales y
                      los trate conforme se acuerda en la Política de Privacidad.
                    </p>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      El Usuario se compromete a hacer un uso adecuado de los contenidos y Servicios
                      que TRAZABLE ofrece a través de la Plataforma y con carácter enunciativo pero
                      no limitativo, a no emplearlos para (i) incurrir en actividades ilícitas,
                      ilegales o contrarias a la buena fe y al orden público; (ii) difundir
                      contenidos o propaganda de carácter racista, xenófobo, pornográfico-ilegal, de
                      apología del terrorismo o atentatorio contra los derechos humanos; (iii)
                      provocar daños en los sistemas físicos y lógicos de TRAZABLE, de sus
                      proveedores o de terceras personas, introducir o difundir en la red virus
                      informáticos o cualesquiera otros sistemas físicos o lógicos que sean
                      susceptibles de provocar los daños anteriormente mencionados; (iv) intentar
                      acceder y, en su caso, utilizar las cuentas de correo electrónico de otros
                      Usuarios y modificar o manipular sus mensajes.
                    </p>
                  </li>

                  {/* 8 */}
                  <li>
                    <span className='font-bold uppercase text-2xl'>
                      EXCLUSIÓN DE GARANTÍAS Y RESPONSABILIDAD
                    </span>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      TRAZABLE se reserva el derecho a interrumpir el acceso a los Servicios en
                      cualquier momento y sin previo aviso, ya sea por motivos técnicos, de
                      seguridad, de control, de mantenimiento, por fallos de suministro eléctrico o
                      por cualquier otra causa justificada. En consecuencia, TRAZABLE no garantiza
                      la fiabilidad, la disponibilidad ni la continuidad permanente de la Plataforma
                      ni de los Servicios, por lo que la utilización de los mismos por parte de los
                      Usuarios se lleva a cabo por su propia cuenta y riesgo, sin que, en ningún
                      momento, puedan exigirse responsabilidades a TRAZABLE este sentido.
                    </p>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      Además, TRAZABLE no asume responsabilidad alguna derivada, a título
                      enunciativo, pero no limitativo:
                      <ul className='list-disc list-inside pl-4 mt-2 mb-12'>
                        <li>
                          De la utilización que los Usuarios hagan de los materiales dispuestos en
                          la Plataforma, ya sean prohibidos o permitidos, en infracción de los
                          derechos de propiedad intelectual y/o industrial de contenidos de la
                          propia web o de los portales de terceros.
                        </li>
                        <li>
                          De los eventuales daños y perjuicios a los Usuarios causados por un
                          funcionamiento normal o anormal de las herramientas de búsqueda, de la
                          organización o la localización de los contenidos y/o acceso a los
                          Servicios y, en general, de los errores o problemas que se generen en el
                          desarrollo o instrumentación de los elementos técnicos que forman el
                          Servicio.
                        </li>
                        <li>
                          De los contenidos de aquellas páginas a las que los Usuarios puedan
                          acceder desde enlaces incluidos en la Plataforma.
                        </li>
                        <li>
                          De los actos u omisiones de terceros, con independencia de que estos
                          terceros pudiesen estar unidos a TRAZABLE vía contractual.
                        </li>
                      </ul>
                    </p>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      De igual modo, TRAZABLE excluye cualquier responsabilidad por los daños y
                      perjuicios de toda clase que puedan deberse a la presencia de virus o a la
                      presencia de otros elementos lesivos en los contenidos que puedan producir
                      alteración en los sistemas informáticos, así como en los documentos o sistemas
                      almacenados en los mismos, por lo que TRAZABLE no será responsable en ningún
                      caso cuando se produzcan:
                      <ul className='list-disc list-inside pl-4 mt-2 mb-12'>
                        <li>
                          Errores o retrasos en el acceso a los Servicios por parte del Usuario a la
                          hora de introducir sus datos en el formulario correspondiente o cualquier
                          anomalía que pueda surgir cuando estas incidencias sean debidas a
                          problemas en la red Internet, causas de caso fortuito o fuerza mayor y
                          cualquier otra contingencia imprevisible ajena a la buena fe de TRAZABLE.
                        </li>
                        <li>
                          Fallos o incidencias que pudieran producirse en las comunicaciones,
                          borrado o transmisiones incompletas, de manera que no se garantiza que los
                          Servicios de la Plataforma estén constantemente operativos.
                        </li>
                        <li>
                          De los errores o daños producidos al sitio web o la aplicación por un uso
                          del Servicio ineficiente y de mala fe por parte del Usuario.
                        </li>
                        <li>
                          De la no operatividad o problemas en la dirección de email facilitada por
                          el Usuario para el envío de la información solicitada.
                        </li>
                      </ul>
                    </p>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      En todo caso, TRAZABLE se compromete a solucionar los problemas que puedan
                      surgir y a ofrecer todo el apoyo necesario al Usuario para llegar a una
                      solución rápida y satisfactoria de la incidencia.
                    </p>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      Con respecto a las comisiones que puedan generarse por el uso de pasarelas de
                      pago para la adquisición de tokens, TRAZABLE no soportará en ningún caso
                      dichas comisiones, y las repercutirá al Usuario.
                    </p>
                    <ol className='list-bold list-decimal list-outside mt-12 text-xl text-gray-500 leading-8'>
                      <li>
                        <span className='font-bold text-xl'>Demoras. Cancelaciones.</span>
                        <p className='mt-8 text-xl text-gray-500 leading-8'>
                          El Usuario acepta que cualquier tipo de operación con Criptoactivos puede
                          sufrir demoras o cancelaciones. En lo que a TRAZABLE respecta, y siempre
                          dentro de las limitaciones existentes, efectuará esfuerzos razonables para
                          que toda operación con Criptoactivos sea efectuada dentro de las 24 horas
                          hábiles siguientes desde la orden de compra o venta de Criptoactivos
                          correctamente cursada.{' '}
                        </p>
                      </li>
                      <li>
                        <span className='font-bold text-xl'>
                          Exclusión de garantías y responsabilidad con respecto a Blockchain. Forks
                          y modificaciones en los protocolos Blockchain.
                        </span>
                        <p className='mt-8 text-xl text-gray-500 leading-8'>
                          TRAZABLE no posee ni controla los protocolos de software subyacentes que
                          rigen el funcionamiento de las Blockchain ligadas a los Criptoactivos
                          compatibles con la Plataforma. En su mayoría dichos protocolos son open
                          source o de código abierto y cualquier tercero puede usarlos, copiarlos,
                          modificarlos y distribuirlos. En consecuencia, TRAZABLE no asume ninguna
                          responsabilidad por el funcionamiento de tales protocolos ni garantiza su
                          funcionalidad, seguridad o disponibilidad. El Usuario reconoce y acepta el
                          riesgo de que los protocolos de software subyacentes relacionados con
                          cualquier Criptoactivos que adquiera puedan cambiar. En particular, los
                          Usuarios deben considerar las bifurcaciones o "Forks" con el impacto que
                          eventualmente puedan tener sobre el valor de las Criptoactivos.
                        </p>
                        <p className='mt-8 text-xl text-gray-500 leading-8'>
                          En el caso de ocurrir un Fork, el Usuario acepta que TRAZABLE pueden
                          suspender sus operaciones de forma temporal (con o sin aviso previo) y, si
                          así lo cree necesario, (a) configurar o reconfigurar sus sistemas o (b)
                          decidir no soportar (o dejar de soportar) un protocolo y/o el Criptoactivo
                          original y/o el alternativo, siempre y cuando el Usuario tenga la
                          oportunidad de retirar sus Criptoactivos de la Plataforma.
                        </p>
                        <p className='mt-8 text-xl text-gray-500 leading-8'>
                          Asimismo, TRAZABLE notificará a los Usuarios de cualesquiera
                          modificaciones de los protocolos de las Blockchains asociadas a los
                          Criptoactivos y cuya implementación no sea posible de realizar en la
                          Plataforma.
                        </p>
                        <p className='mt-8 text-xl text-gray-500 leading-8'>
                          El Usuario reconoce y acepta que TRAZABLE no asume ninguna responsabilidad
                          de cualesquiera consecuencias en la prestación del Servicio que puedan
                          surgir en relación con la modificación de los protocolos Blockchain.
                        </p>
                        <p className='mt-8 text-xl text-gray-500 leading-8 font-bold'>
                          Asimismo, el Usuario reconoce y acepta que las transacciones realizadas en
                          Blockchain son irreversibles una vez confirmadas a través del protocolo
                          establecido en cada caso y que TRAZABLE no tiene capacidad de revertir las
                          mismas una vez han sido confirmadas.
                        </p>
                      </li>
                    </ol>
                  </li>

                  {/* 9 */}
                  <li>
                    <span className='font-bold uppercase text-2xl'>Prohibiciones al Usuario</span>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      Sin que la lista sea exhaustiva, el Usuario se obliga a abstenerse de realizar
                      las siguientes acciones, ya sea directamente o a través de un tercero:
                      <ul className='list-disc list-inside pl-4 mt-2 mb-12'>
                        <li>
                          Utilizar la Plataforma, los Servicios o cualquiera de sus contenidos de un
                          modo o con fines que TRAZABLE considere distintos o contrarios a estos
                          Términos y Condiciones, las leyes, la moral, las buenas costumbres o el
                          orden público.
                        </li>
                        <li>
                          Utilizar la Plataforma para enviar, reproducir o publicar archivos o
                          cualquier tipo de información cuyo contenido sea obsceno, abusivo,
                          difamatorio, injurioso, pornográfico, político o contrario a las leyes, la
                          moral y las buenas costumbres, o que contenga software malicioso como
                          virus, gusanos o que contenga cualquier otra característica capaz de
                          destruir o dañar el funcionamiento de un dispositivo electrónico, de la
                          Plataforma, o de los sistemas o servidores de TRAZABLE, o de un tercero.
                        </li>
                        <li>
                          Utilizar la Plataforma para enviar, reproducir o publicar archivos en
                          violación a derechos de propiedad intelectual de terceros.
                        </li>
                        <li>
                          Explotar o aprovechar un ataque a la TRAZABLE, o una vulnerabilidad o un
                          error de la Plataforma, en beneficio propio o de un tercero.
                        </li>
                        <li>
                          Utilizar la Plataforma en representación o por cuenta de terceros (salvo
                          en los supuestos señalados en las presentes Términos y Condiciones) o
                          permitir a cualquier persona diferente del Usuario (incluyendo, sin
                          limitación, a través de cesión, licencia o transferencia) a utilizar su
                          cuenta y los Servicios asociados a la misma.
                        </li>
                        <li>
                          Usar programas, software o dispositivos automáticos o manuales para
                          monitorear o copiar la información o cualquier tipo de contenido o
                          elemento de la Plataforma.
                        </li>
                        <li>
                          Vincular de cualquier modo a la Plataforma o a los Servicios a promociones
                          no solicitadas, campañas políticas o mensajes comerciales (SPAM) o a
                          cualquier mensaje en cadena o con contenido no veraz para obtener
                          beneficios propios o de terceros.
                        </li>
                        <li>
                          Efectuar acciones que restrinjan, denieguen o impidan a terceros, el
                          acceso y uso de la Plataforma, así como a los contenidos y Servicios
                          ofrecidos a través de la misma.
                        </li>
                        <li>
                          Intentar violar cualesquiera sistemas de autenticación y seguridad de la
                          Plataforma.
                        </li>
                        <li>
                          Intentar obtener cualquier clase de material o contenido accesible a
                          través de la Plataforma empleando procedimientos o medios distintos de los
                          que, según el caso, se hayan expresamente indicado o puesto a disposición
                          del Usuario.
                        </li>
                        <li>
                          Incluir frames, links, deep links, o enlaces a la Plataforma, tanto desde
                          otros sitios web como de cualquier software o dispositivo electrónico.
                        </li>
                        <li>
                          Realizar cualquier acción que imponga una carga desproporcionada o
                          desmesurada sobre la infraestructura tecnológica de la Plataforma.
                        </li>
                        <li>
                          Utilizar meta tags o algún otro tipo de texto oculto con el nombre
                          “TRAZABLE” o cualquier otro nombre o denominación comercial y/o de
                          derechos de propiedad intelectual que pertenezcan o sean de titularidad de
                          TRAZABLE.
                        </li>
                        <li>
                          No se permiten a los usuarios agregar liquidez en diferentes exchanges
                          descentralizados. Trazable se reserva el derecho a tomar acciones legales
                          en caso de que un usuario realice esta acción.
                        </li>
                      </ul>
                    </p>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      TRAZABLE se reserva el derecho a denegar o retirar el acceso a la Plataforma
                      y/o los servicios ofrecidos en la misma sin necesidad de preaviso a aquellos
                      Usuarios que realicen cualesquiera de las acciones indicadas en el presente
                      apartado.
                    </p>
                  </li>

                  {/* 10 */}
                  <li>
                    <span className='font-bold uppercase text-2xl'>
                      Solicitud de baja y cierre de la Cuenta
                    </span>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      El Usuario puede solicitar la baja de su cuenta de usuario a través de la
                      Plataforma, o vía correo electrónico a support@trazable.io únicamente desde la
                      dirección de correo con la cual se registró.
                    </p>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      TRAZABLE se reserva el derecho a solicitar comprobantes y/o información
                      adicional a efectos de corroborar la veracidad de la solicitud de baja y de la
                      información suministrada por el Usuario.
                    </p>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      No se considerará finalizada la relación contractual hasta que TRAZABLE haya
                      cancelado la cuenta, dado de baja al Usuario de la Plataforma y haya
                      notificado la misma al Usuario.
                    </p>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      El número de documento y el correo electrónico asociados al Usuario
                      permanecerán bloqueados, de modo que ninguna otra persona podrá crear una
                      nueva cuenta de usuario utilizando los mismos.
                    </p>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      La información sobre transacciones no será eliminada. Respecto al plazo de
                      conservación de los datos personales del Usuario, se encuentran
                      permanentemente disponibles en la{' '}
                      <Link to={PAGES.PRIVACY_POLICY} className='text-blue-500 underline'>
                        Política de Privacidad
                      </Link>
                      .
                    </p>
                  </li>

                  {/* 11 */}
                  <li>
                    <span className='font-bold uppercase text-2xl'>
                      seguridad. recuperación de contraseñas
                    </span>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      TRAZABLE pone a disposición del Usuario información sobre la seguridad de la
                      contraseña elegida.
                    </p>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      TRAZABLE no se hace responsable de la pérdida de fondos derivados de
                      sustracciones de contraseñas.
                    </p>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      En línea con lo establecido en los presentes Términos y Condiciones, TRAZABLE
                      asume que cualesquiera movimientos que se realicen en la cuenta de un Usuario,
                      han sido efectuados por el mismo, por lo que el Usuario reconoce y acepta ser
                      el exclusivo responsable del uso que se haga de su cuenta y de las
                      consecuencias y daños directos e indirectos que de él se deriven.
                    </p>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      En caso de que el Usuario detecte que su cuenta ha sido atacada o que un
                      tercero ha realizado movimientos a través de la misma, deberá notificarlo a la
                      mayor brevedad a TRAZABLE para proceder al bloqueo de la cuenta a través de un
                      email a la siguiente dirección: support@trazable.io
                    </p>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      En caso de que el Usuario olvide o pierda su contraseña podrá recuperarla por
                      los medios disponibles a través de la Plataforma, o contactar con TRAZABLE a
                      través de un email a la siguiente dirección: support@trazable.io
                    </p>
                  </li>

                  {/* 12 */}
                  <li>
                    <span className='font-bold uppercase text-2xl'>DERECHO DE DESISTIMIENTO</span>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      En virtud del artículo 103 de la Ley 3/2014 de 27 de marzo, las operaciones
                      recogidas en los presente términos y condiciones quedan excluidas del derecho
                      de desistimiento.
                    </p>
                  </li>

                  {/* 13 */}
                  <li>
                    <span className='font-bold uppercase text-2xl'>
                      Modificaciones de los Términos
                    </span>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      TRAZABLE podrá modificar en cualquier momento las condiciones aquí
                      determinadas, siendo debidamente publicadas en la Plataforma y/o, a exclusivo
                      criterio de TRAZABLE, notificando a los Usuarios de la misma por correo
                      electrónico.
                    </p>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      El uso de la Plataforma por parte del Usuario desde la notificación de la
                      modificación de los presentes Términos y Condiciones constituirán la
                      aceptación íntegra de los mismos por parte del Usuario; en caso de no estar de
                      acuerdo con los nuevos términos, el Usuario deberá dejar de utilizar la
                      Plataforma.
                    </p>
                  </li>

                  {/* 14 */}
                  <li>
                    <span className='font-bold uppercase text-2xl'>Propiedad Intelectual</span>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      Todo el contenido de la Plataforma, incluyendo sin limitación el diseño de sus
                      pantallas, materiales promocionales, las marcas, nombres comerciales, signos
                      distintivos, textos, gráficos, logos, imágenes, íconos, botones, videos,
                      sonidos, música, bases de datos, código fuente, software y combinaciones de
                      colores (en adelante, el “Contenido”), es de titularidad de TRAZABLE o de sus
                      licenciantes.
                    </p>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      El Contenido no podrá ser reproducido, modificado, transformado, editado,
                      traducido, cedido, distribuido, representado, comercializado, comunicado
                      públicamente, almacenado, usado para fines distintos de los previstos en estos
                      Términos, ni ser objeto de obras derivadas, sin autorización previa y por
                      escrito del titular de los derechos.
                    </p>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      El hecho de que el Usuario pueda acceder a la Plataforma no le otorga
                      licencia, derecho ni titularidad alguna sobre los derechos de propiedad
                      industrial o intelectual sobre la totalidad ni sobre porción alguna del
                      Contenido. Solamente se otorga a los Usuarios, sobre la Plataforma y sobre el
                      Contenido, una licencia no exclusiva, revocable y limitada para acceder a, y
                      usar la Plataforma de conformidad con estos Términos y Condiciones.
                    </p>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      Si el Usuario considera que en la Plataforma o en el Contenido se violara
                      algún derecho de propiedad intelectual de terceros, deberá notificarlo a
                      TRAZABLE a la dirección indicada en los presentes Términos y Condiciones,
                      acompañando toda la información y documentación necesaria que respalde la
                      mencionada consideración.
                    </p>
                  </li>

                  {/* 15 */}
                  <li>
                    <span className='font-bold uppercase text-2xl'>
                      TRATAMIENTO DE DATOS Y UTILIZACIÓN DE COOKIES
                    </span>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      De acuerdo con lo establecido en el Reglamento General de Protección de Datos
                      de Carácter Personal y demás normativa aplicable en la materia, le informamos
                      de que los datos personales que nos facilite e usuario a través de la
                      Plataforma o mediante envíos de correos electrónicos, serán tratados por
                      TRAZABLE como responsable del tratamiento para poder llevar a cabo el registro
                      de Usuarios, realizar las transacciones solicitadas, atender las dudas de los
                      Usuarios, así como recibir notificaciones periódicas a través de medios
                      electrónicas. El Usuario puede ampliar más información sobre el tratamiento de
                      sus datos en la política de privacidad, la cual debe ser leída, y comprendida
                      para la utilización de los Servicios.
                    </p>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      TRAZABLE se compromete al cumplimiento de la obligación de secreto de los
                      datos de carácter personal, por ello ha adoptado las medidas necesarias para
                      evitar su alteración, pérdida, tratamiento o acceso no autorizado, habida
                      cuenta en todo momento del estado de la tecnología.
                    </p>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      Por otra parte, TRAZABLE informa que por visitar su Plataforma (sin llevar a
                      cabo el proceso de registro por parte del Usuario) no queda registrado de
                      forma automática ningún dato de carácter personal que identifique a un
                      usuario, en cambio existe determinada información de carácter no personal y no
                      identificable con un usuario concreto que se recoge durante la sesión en
                      directo a través de dispositivos denominados “cookies” que nos permiten
                      obtener información estadística sobre el uso de la Plataforma para luego poder
                      realizar mejoras. Todo Usuario debe aceptar, rechazar o configurar las cookies
                      para navegar por la Plataforma a través del banner emergente al entrar en la
                      misma. Para más información, consultar nuestra política de cookies.
                    </p>
                  </li>

                  {/* 16 */}
                  <li>
                    <span className='font-bold uppercase text-2xl'>
                      PRIVACIDAD Y CADENAS DE BLOQUES
                    </span>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      La adquisición de Criptoactivos pueden registrarse en un Blockchain o cadena
                      de bloques pública. Las cadenas de bloques públicas operan como libros de
                      contabilidad, destinados a registrar de manera inmutable las transacciones en
                      redes de sistemas informáticos. Muchas de estas Blockchain públicas permiten
                      el análisis forense que puede conducir a la desanonimización y a la revelación
                      involuntaria de información financiera privada, especialmente cuando los datos
                      de la cadena de bloques se combinan con otros datos.
                    </p>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      Dado que las cadenas de bloques son redes descentralizadas o de terceros que
                      no están controladas u operadas por TRAZABLE, no podemos borrar, modificar o
                      alterar los datos personales de dichas redes. Para más información puede
                      consultar nuestra política de privacidad.{' '}
                    </p>
                  </li>

                  {/* 17 */}
                  <li>
                    <span className='font-bold uppercase text-2xl'>
                      LEGISLACIÓN APLICABLE Y JURISDICCIÓN
                    </span>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      Para cuantas cuestiones interpretativas o litigiosas relacionadas con el
                      portal web o la app, será de aplicación la legislación española y en caso de
                      controversia, ambas partes acuerdan someterse, con renuncia a cualquier otro
                      fuero que pudiera corresponderle, a la jurisdicción de los Juzgados y
                      Tribunales de la ciudad de Valencia (España) siempre y cuando dicha
                      controversia no verse sobre cualquier transacción económica, la cual se
                      resolverá mediante la jurisdicción de los Juzgados y Tribunales del domicilio
                      del consumidor o presentando la reclamación oportuna rellenando el siguiente
                      formulario electrónico:{' '}
                      <a
                        href='http://ec.europa.eu/consumers/odr/'
                        className='underline text-blue-500'
                        target='_blank'
                        rel='noreferrer'
                      >
                        http://ec.europa.eu/consumers/odr/
                      </a>
                    </p>
                  </li>

                  {/* 18 */}
                  <li>
                    <span className='font-bold uppercase text-2xl'>ENLACES</span>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      En el caso de que se dispusiesen enlaces o hipervínculos hacía otros sitios de
                      Internet, TRAZABLE no ejercerá ningún tipo de control sobre dichos sitios y
                      contenidos. En ningún caso TRAZABLE asumirá responsabilidad alguna por los
                      contenidos de algún enlace perteneciente a un sitio web ajeno, ni garantizará
                      la disponibilidad técnica, calidad, fiabilidad, exactitud, amplitud,
                      veracidad, validez y constitucionalidad de cualquier material o información
                      contenida en ninguno de dichos hipervínculos u otros sitios de Internet.
                      Igualmente, la inclusión de estas conexiones externas no implicará ningún tipo
                      de asociación, fusión o participación con las entidades conectadas.
                    </p>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      Queda expresamente prohibida la introducción de hiperenlaces con fines
                      mercantiles en páginas web ajenas a TRAZABLE que permitan el acceso a la
                      Plataforma sin consentimiento expreso de TRAZABLE. En todo caso, la existencia
                      de hiperenlaces en sitios web ajenos a TRAZABLE, no implicará en ningún caso
                      la existencia de relaciones comerciales o mercantiles con el titular de la
                      página web donde se establezca el hiperenlace, ni la aceptación por parte de
                      TRAZABLE.
                    </p>
                  </li>

                  {/* 19 */}
                  <li>
                    <span className='font-bold uppercase text-2xl'>Misceláneas</span>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      En caso de que una o más cláusulas de estos Términos y Condiciones se
                      declarasen nulas por cualquier autoridad u órgano judicial competente, tal
                      nulidad no afectará la validez de las restantes cláusulas, que mantendrán su
                      plena vigencia y efecto.
                    </p>
                  </li>

                  {/* 20 */}
                  <li>
                    <span className='font-bold uppercase text-2xl'>Cesión</span>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      El Usuario no podrá ceder a ningún tercero su posición contractual, este
                      contrato, ni cualquiera de los derechos u obligaciones emergentes del mismo.
                    </p>
                  </li>

                  {/* 21 */}
                  <li>
                    <span className='font-bold uppercase text-2xl'>
                      Consentimiento para notificaciones electrónicas
                    </span>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      El Usuario otorga su expreso consentimiento para que toda información
                      relacionada con su calidad de Usuario, su cuenta y el uso de la Plataforma le
                      sea notificada o informada por medios electrónicos.
                    </p>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      Con su consentimiento, enviamos notificaciones automáticas (push) a su
                      dispositivo móvil para proporcionar información sobre variaciones en la
                      cotización de las Criptoactivos, actualizaciones del Servicio, comunicaciones
                      promocionales y otros mensajes relacionados. El Usuario podrá desactivar las
                      notificaciones automáticas cambiando su configuración de notificaciones a
                      través de su dispositivo con el cual accede a la Plataforma.
                    </p>
                  </li>

                  {/* 22 */}
                  <li>
                    <span className='font-bold uppercase text-2xl'>
                      CONTACTO Y Atención al Usuario
                    </span>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      TRAZABLE cuenta con servicio de atención al Usuario, de lunes a viernes de 9 a
                      18 horas, a través del envío de un correo electrónico a la siguiente
                      dirección: info@trazable.io
                    </p>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </>
      ) : (
        // English Translation
        <>
          <div className='relative bg-white overflow-hidden z-0'>
            <div className='max-w-7xl mx-auto'>
              <div className='relative z-10 pt-8 pb-8 bg-white sm:pb-16 md:pb-20 lg:w-full lg:pb-28 xl:pb-0'>
                <main className='mx-auto px-4 sm:px-6 lg:px-8 sm:pt-24'>
                  <div className='text-center'>
                    <h1 className='text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl'>
                      <span className='block xl:inline'>
                        TERMS AND CONDITIONS OF SERVICE AND PUBLIC OFFERING TOKENS
                      </span>
                    </h1>
                  </div>
                </main>
              </div>
            </div>
          </div>
          <div className='relative py-16 bg-white overflow-hidden'>
            <div className='hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full'>
              <div className='relative h-full text-lg max-w-prose mx-auto' aria-hidden='true'>
                <svg
                  className='absolute top-12 left-full transform translate-x-32'
                  width='404'
                  height='384'
                  fill='none'
                  viewBox='0 0 404 384'
                >
                  <defs>
                    <pattern
                      id='74b3fd99-0a6f-4271-bef2-e80eeafdf357'
                      x='0'
                      y='0'
                      width='20'
                      height='20'
                      patternUnits='userSpaceOnUse'
                    >
                      <rect
                        x='0'
                        y='0'
                        width='4'
                        height='4'
                        className='text-gray-200'
                        fill='currentColor'
                      />
                    </pattern>
                  </defs>
                  <rect
                    width='404'
                    height='384'
                    fill='url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)'
                  />
                </svg>
                <svg
                  className='absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32'
                  width='404'
                  height='384'
                  fill='none'
                  viewBox='0 0 404 384'
                >
                  <defs>
                    <pattern
                      id='f210dbf6-a58d-4871-961e-36d5016a0f49'
                      x='0'
                      y='0'
                      width='20'
                      height='20'
                      patternUnits='userSpaceOnUse'
                    >
                      <rect
                        x='0'
                        y='0'
                        width='4'
                        height='4'
                        className='text-gray-200'
                        fill='currentColor'
                      />
                    </pattern>
                  </defs>
                  <rect
                    width='404'
                    height='384'
                    fill='url(#f210dbf6-a58d-4871-961e-36d5016a0f49)'
                  />
                </svg>
                <svg
                  className='absolute bottom-12 left-full transform translate-x-32'
                  width='404'
                  height='384'
                  fill='none'
                  viewBox='0 0 404 384'
                >
                  <defs>
                    <pattern
                      id='d3eb07ae-5182-43e6-857d-35c643af9034'
                      x='0'
                      y='0'
                      width='20'
                      height='20'
                      patternUnits='userSpaceOnUse'
                    >
                      <rect
                        x='0'
                        y='0'
                        width='4'
                        height='4'
                        className='text-gray-200'
                        fill='currentColor'
                      />
                    </pattern>
                  </defs>
                  <rect
                    width='404'
                    height='384'
                    fill='url(#d3eb07ae-5182-43e6-857d-35c643af9034)'
                  />
                </svg>
              </div>
            </div>
            <div className='relative px-4 sm:px-6 lg:px-8'>
              <div className='text-lg max-w-prose mx-auto'>
                <h1>
                  <span className='mt-12 block text-3xl text-center leading-8 font-extrabold uppercase tracking-tight text-gray-900 sm:text-4xl'>
                    DEFINITIONS
                  </span>
                </h1>
                <ul className='list-disc list-inside pl-4 mt-2'>
                  <li>
                    <b> TRAZABLE</b>: Blockchain traceability
                  </li>
                  <li>
                    <b>Blockchain</b>: the decentralized and distributed public network that
                    TRAZABLE uses for the proper provision of the Service.
                  </li>
                  <li>
                    <b>Platform</b>: website https://trazable.io through which the User accesses the
                    Services.
                  </li>
                </ul>
                <p className='mt-8 text-xl text-gray-500 leading-8'>Service/Services:</p>
                <ul className='list-disc list-inside pl-4 mt-2 mb-12'>
                  <li>
                    <b>Wallet</b>: set of cryptographic elements that allow the custody of the
                    balance of Cryptoassets deposited in it by a single User.
                  </li>
                  <li>
                    <b>Cryptoassets or tokens:</b>: digital assets or virtual digital currencies
                    issued through computer protocols and not backed by an entity or Central Bank.
                    For the purposes of these Terms and Conditions, the following digital assets
                    shall be considered Cryptoassets
                  </li>
                  <li>
                    <b>User</b>: natural person registered on the Platform accessing the Services.
                  </li>
                  <li>
                    <b>KYC/AML</b>: "know your customer" procedure whose objective is the
                    identification of natural and legal persons for compliance with the regulations
                    for the prevention of money laundering, and specifically, Law 10/2010, of 28th
                    April, on the prevention of money laundering and the financing of terrorism, as
                    well as the regulations that develop it, as amended by Royal Decree-Law 7/2021,
                    of 27th April, on the transposition of European Union directives in the areas of
                    competition, prevention of money laundering, credit institutions,
                    telecommunications, tax measures, prevention and repair of environmental damage,
                    posting of workers in the provision of transnational services and consumer
                    protection.
                  </li>
                </ul>

                <ol className='list-bold list-decimal list-outside mt-12 text-xl text-gray-500 leading-8'>
                  {/* 1 */}
                  <li>
                    <span className='font-bold uppercase text-2xl'>INTRODUCTION</span>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      Welcome to the TRAZABLE web application (the "Platform", or "we" or "us"
                      collectively). Below, we will detail the license agreement that governs the
                      terms on which you, as a User, may use the Platform and the Services offered
                      therein.
                    </p>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      This document (together with all documents referred to herein) sets out the
                      terms and conditions governing the public sale of tokens on this Platform, and
                      the use of the Service provided through this Platform ("Terms and
                      Conditions").
                    </p>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      Please read these Terms and Conditions and our Privacy and Cookie Policy
                      carefully before using the Platform.
                    </p>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      By using the service provided on this Platform, the User agrees to be bound by
                      the Terms and Conditions, as well as by our Privacy and Cookies Policy.{' '}
                      <Link className='underline, text-blue-500' to={PAGES.PRIVACY_POLICY}>
                        Privacy
                      </Link>{' '}
                      and{' '}
                      <Link className='underline, text-blue-500' to={PAGES.COOKIES}>
                        Cookie Policy
                      </Link>
                      , so if the User does not agree with all the stipulations of the Terms and
                      Conditions and the Privacy and Cookies Policy, you should not use this
                      Platform.
                    </p>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      If the User has any questions regarding the terms and conditions or the
                      Privacy and Cookies Policy, he/she can contact us through our contact
                      channels.
                    </p>
                  </li>

                  {/* 2 */}
                  <li>
                    <span className='font-bold uppercase text-2xl'>LEGAL INFORMATION</span>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      In compliance with the duty of information contained in Article 10 of Law
                      34/2002, of July 11, Services of the Information Society and Electronic
                      Commerce, the following data of the owner are reflected below:
                    </p>
                    <ul className='list-disc list-inside pl-4 mt-2 mb-12'>
                      <li>Name: BLOCKCHAIN TRACEABILITY, S.L.</li>
                      <li>Tax Identification Number: B-40547507</li>
                      <li>Registered office: Avd/Amado Granell Mesado 65, 46013 Valencia</li>
                    </ul>
                  </li>

                  {/* 3 */}
                  <li>
                    <span className='font-bold uppercase text-2xl'>USERS and consent</span>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      The use of the Platform attributes the condition of user, who accepts, from
                      access, download and / or use of it, the Terms and Conditions reflected here.
                    </p>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      Furthermore, the acquisition of tokens from the Platform implies acceptance of
                      the Terms and Conditions reflected herein.
                    </p>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      In order to use the Services offered on the Platform, the User must be over 18
                      years.
                    </p>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      The Platform will allow the User to access the Services specified in these
                      Terms and Conditions.
                    </p>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      Before making use of the Services, you should be aware that the risk of loss
                      in buying, selling or holding Cryptoassets may be substantial. As with any
                      asset, the value of Cryptoassets can vary considerably and there is a
                      substantial risk that you may lose money buying, selling, holding or investing
                      in Cryptoassets. Therefore, by using the Platform, the User acknowledges and
                      accepts the risks involved in buying, selling, holding or investing in
                      Cryptoassets.
                    </p>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      Likewise, by using the Platform the User agrees that TRAZABLE does not provide
                      any investment advice in relation to the Cryptoassets that can be acquired
                      through the Platform, and in no case shall constitute financial or investment
                      advice and shall not be construed by the User as such. Any decision to buy or
                      sell Cryptoassets is the sole decision of the User, and TRAZABLE shall in no
                      event be liable for any loss suffered as a result of such decision.
                    </p>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      Cryptoasset services are not currently regulated by the financial authorities,
                      nor do they fall under the umbrella of protection that may be offered by
                      financial services clearing schemes in Spain, therefore, the User should
                      carefully consider whether trading or holding Cryptoassets is appropriate.
                    </p>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      Furthermore, by accepting these terms and conditions,{' '}
                      <b>the User acknowledges and agrees that</b>:
                      <ul className='list-disc list-inside pl-4 mt-2 mb-12'>
                        <li>Cryptoasset prices are extremely volatile.</li>
                        <li>
                          The User is solely responsible for determining what taxes apply to their
                          Cryptoasset transactions and for fulfilling their tax obligations.
                        </li>
                      </ul>
                    </p>
                  </li>

                  {/* 4 */}
                  <li>
                    <span className='font-bold uppercase text-2xl'>
                      PUBLIC TOKEN OFFERING USE OF THE PLATFORM
                    </span>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      The Public Offering is constituted by the generation, by means of Blockchain
                      technology, of the cryptographic Tokens mentioned in the definitions section
                      of these Terms and Conditions, which will be made available to the User, who
                      will be able to acquire them at a predetermined price, in exchange for fiat
                      money.
                    </p>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      TRZ is the token to be issued by the Issuer, as a utility token.
                    </p>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      The token will be executed on the Ethereum network blockchain following the
                      ERC-20 standard as well as on the Binance Smart Chain following the BEP-20
                      standard.
                    </p>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      The characteristics of the Public Offering are as follows:
                      <ul className='list-disc list-inside pl-4 mt-2 mb-12'>
                        <li>
                          Phase 1:
                          <ul className='list-circle list-inside pl-4 mt-2 mb-12'>
                            <li>Starts December 6, 2021</li>
                            <li>Ends December 12, 2021</li>
                            <li>Lock-up period of 3 months from the end of public offering</li>
                            <li>Vesting period of 3 months from the end of the lock-up period. </li>
                            <li>Minimum purchase of 500 €.</li>
                            <li>Maximum purchase of 50.000 €.</li>
                            <li>Maximum number of tokens issued 75,000,000</li>
                            <li>Price per unit from 0.015€.</li>
                          </ul>
                        </li>
                        <li>
                          Phase 2:
                          <ul className='list-circle list-inside pl-4 mt-2 mb-12'>
                            <li>Starts December 13, 2021</li>
                            <li>Ends December 19, 2021</li>
                            <li>Lock-up period of 1 month from the end of the public offering</li>
                            <li>Vesting period of 1 month from the end of the lock-up period.</li>
                            <li>Minimum purchase of 100 €.</li>
                            <li>Maximum purchase of 10.000 €.</li>
                            <li>Maximum number of tokens issued 75,000,000</li>
                            <li>Price per unit from 0.02€.</li>
                          </ul>
                        </li>
                      </ul>
                    </p>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      The User acknowledges that the Tokens are subject to a Vesting and lock up
                      period (Lock up period) according to which the quantity of Tokens acquired is
                      subject to a lock up period and a gradual availability period (Vesting
                      period).
                    </p>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      The release of Tokens within this Vesting Period will be 1 month or 3 months
                      (depending on the acquisition phase) from the end of the Lock up period (i.e.
                      1 or 3 months from the end of the Public Offering).
                    </p>
                  </li>

                  {/* 5 */}
                  <li>
                    <span className='font-bold uppercase text-2xl'>
                      WALLET AND USER IDENTIFICATION
                    </span>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      The User must provide the Wallet in which the Tokens must be delivered. In the
                      event that the User does not provide the Wallet address in the time and manner
                      requested, TRAZABLE will unilaterally decide (I) whether to proceed to refund
                      the investment provided by the user; or (ii) send the tokens once the user
                      provides his/her password. In case (II), the user assumes that the tokens will
                      not be delivered within the agreed deadline, given the lack of information by
                      TRAZABLE to meet it and TRAZABLE will decide the new delivery time.
                    </p>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      Only one Wallet may be provided per User.
                    </p>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      In the event that the User modifies his Wallet, he must communicate this
                      change before the end of the Vesting period on the Trazable platform.
                    </p>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      In order to proceed with the purchase of Token, the User will be required to
                      provide the personal data necessary for its effective identification (KYC), as
                      well as the corresponding documentation that accredits it, for the purpose of
                      preventing any potential money laundering and terrorist financing conduct and
                      to be able to adopt appropriate due diligence measures. The User irrevocably
                      undertakes to ensure that the information provided is truthful. In the same
                      way, the User must complete the responsible declarations that may be requested
                      in each case.
                    </p>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      TRAZABLE is not responsible in the event that the User provides an incorrect
                      Wallet address or changes the address of the Wallet and does not notify
                      Trazable as indicated in this terms and conditions.
                    </p>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      Trazable only recommends Metamask as a wallet for receiving the tokens. The
                      use of another wallet that is not the one recommended by Trazable is under the
                      user's responsibility, as well as the loss of tokens due to the use of another
                      wallet that is not the one recommended.
                    </p>
                  </li>

                  {/* 6 */}
                  <li>
                    <span className='font-bold uppercase text-2xl'>TOKEN UTILITIES </span>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      The functionalities of the token are those specified in the{' '}
                      <a
                        href={pdfWhitepaperByLang}
                        target='_blank'
                        className='underline text-blue-500'
                        rel='noreferrer'
                      >
                        Whitepaper
                      </a>
                      .
                    </p>
                  </li>

                  {/* 7 */}
                  <li>
                    <span className='font-bold uppercase text-2xl'>GENERAL</span>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      TRAZABLE offers through the Platform the Services specified in these Terms and
                      Conditions.
                    </p>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      The User assumes responsibility for the use of the Platform. This
                      responsibility extends to the registration required to access the Services.
                    </p>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      In the registration process, the User will be required to provide the personal
                      data necessary for his or her effective identification, as well as the
                      corresponding documentation that accredits him or her, for the purpose of
                      preventing any potential money laundering and terrorist financing conduct and
                      to be able to adopt appropriate due diligence measures. The User irrevocably
                      undertakes to guarantee that the information provided is truthful. In the same
                      way, the User must complete the responsible declarations that may be requested
                      in each case.
                    </p>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      TRAZABLE reserves the right to request an update of the information if it is
                      aware that it has undergone any change. Similarly, TRAZABLE reserves the right
                      to make the appropriate checks in order to confirm the legal origin of the
                      contributions of funds that are made. In case of having evidence of
                      irregularities in the registration process and in the subsequent use of the
                      Platform by the User, TRAZABLE will proceed to take action for the purpose of
                      preventing any conduct to prevent money laundering and terrorist financing. As
                      a result of this registration, the User will be provided with a password, for
                      which he/she will be responsible, pledging to make diligent and confidential
                      use of it.
                    </p>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      In case of unauthorized access to the User's account, the User agrees to
                      immediately notify TRAZABLE through the means of contact made available to the
                      User and indicated in these Terms and Conditions.
                    </p>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      The registration process can also be carried out by accessing the Platform
                      through the following social networks used by the User: Instagram, LinkedIn,
                      Telgeram and Twitter. In such cases, the User agrees that TRAZABLE obtains
                      certain personal data from these third parties and treats them as agreed in
                      the Privacy Policy.
                    </p>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      The User agrees to make appropriate use of the content and services that
                      TRAZABLE offers through the Platform and, by way of example but not limited
                      to, not to use them to (i) engage in illicit, illegal or contrary to good
                      faith and public order; (ii) disseminate content or propaganda of a racist,
                      xenophobic, pornographic, illegal, apology of terrorism or against human
                      rights; (iii) cause damage to the physical and logical systems of TRAZABLE,
                      its suppliers or third parties, introduce or spread computer viruses or any
                      other physical or logical systems that are likely to cause the aforementioned
                      damage; (iv) attempt to access and, where appropriate, use the email accounts
                      of other Users and modify or manipulate their messages.
                    </p>
                  </li>

                  {/* 8 */}
                  <li>
                    <span className='font-bold uppercase text-2xl'>
                      DISCLAIMER OF WARRANTIES AND LIABILITY
                    </span>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      TRAZABLE reserves the right to interrupt access to the Services at any time
                      and without prior notice, whether for technical, security, control,
                      maintenance, power failure or any other justified cause. Consequently,
                      TRAZABLE does not guarantee the reliability, availability or continuity of the
                      Platform or the Services, so that the use of the same by the Users is carried
                      out at their own risk, without, at any time, TRAZABLE can be held liable in
                      this regard.
                    </p>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      In addition, TRAZABLE assumes no liability whatsoever arising, by way of
                      example, but not limited to:
                      <ul className='list-disc list-inside pl-4 mt-2 mb-12'>
                        <li>
                          The use that users make of the materials available on the Platform,
                          whether prohibited or permitted, in violation of intellectual property
                          rights and / or industrial content of the website itself or third party
                          portals.
                        </li>
                        <li>
                          For possible damages to Users caused by normal or abnormal functioning of
                          the search tools, the organization or location of the contents and/or
                          access to the Services and, in general, for errors or problems generated
                          in the development or instrumentation of the technical elements that make
                          up the Service.
                        </li>
                        <li>
                          The contents of those pages that users can access from links included in
                          the Platform.
                        </li>
                        <li>
                          The acts or omissions of third parties, regardless of whether these third
                          parties may be linked to TRAZABLE by contract.
                        </li>
                      </ul>
                    </p>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      Similarly, TRAZABLE excludes any liability for damages of any kind that may be
                      due to the presence of viruses or the presence of other harmful elements in
                      the content that may cause alterations in computer systems, as well as
                      documents or systems stored therein, so TRAZABLE will not be liable in any
                      case when they occur:
                      <ul className='list-disc list-inside pl-4 mt-2 mb-12'>
                        <li>
                          Errors or delays in accessing the Services by the User when entering their
                          data in the corresponding form or any anomaly that may arise when these
                          incidents are due to problems in the Internet network, acts of God or
                          force majeure and any other unforeseeable contingency beyond the good
                          faith of TRAZABLE.
                        </li>
                        <li>
                          Failures or incidents that may occur in communications, deletion or
                          incomplete transmissions, so that there is no guarantee that the Platform
                          Services are constantly operational.
                        </li>
                        <li>
                          Errors or damage caused to the website or the application due to
                          inefficient and bad faith use of the Service by the User.
                        </li>
                        <li>
                          Non-operability or problems in the email address provided by the User to
                          send the requested information.
                        </li>
                      </ul>
                    </p>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      In any case, TRAZABLE undertakes to solve the problems that may arise and to
                      provide all necessary support to the User to reach a quick and satisfactory
                      solution to the incident.
                    </p>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      With respect to any commissions that may be generated by the use of payment
                      gateways for the acquisition of tokens, TRAZABLE will not bear these
                      commissions in any case, and will pass them on to the User.
                    </p>
                    <ol className='list-bold list-decimal list-outside mt-12 text-xl text-gray-500 leading-8'>
                      <li>
                        <span className='font-bold text-xl'>Delays. Cancellations. </span>
                        <p className='mt-8 text-xl text-gray-500 leading-8'>
                          The User accepts that any type of transaction with Cryptoassets may suffer
                          delays or cancellations. As far as TRAZABLE is concerned, and always
                          within the existing limitations, it will make reasonable efforts so that
                          any transaction with Cryptoassets is carried out within 24 working hours
                          from the purchase or sale order of Cryptoassets correctly placed.
                        </p>
                      </li>
                      <li>
                        <span className='font-bold text-xl'>
                          Disclaimer of warranties and liability with respect to Blockchain. Forks
                          and modifications to Blockchain protocols.
                        </span>
                        <p className='mt-8 text-xl text-gray-500 leading-8'>
                          TRAZABLE does not own or control the underlying software protocols that
                          govern the operation of the Blockchain linked to the Cryptoassets
                          supported by the Platform. Most of these protocols are open source and can
                          be used, copied, modified and distributed by any third party.
                          Consequently, TRAZABLE assumes no responsibility for the operation of such
                          protocols and does not guarantee their functionality, security or
                          availability. The 5 User acknowledges and accepts the risk that the
                          underlying software protocols related to any Cryptoassets it acquires may
                          change. In particular, Users should consider forks with the impact they
                          may eventually have on the value of the Crypto-assets.
                        </p>
                        <p className='mt-8 text-xl text-gray-500 leading-8'>
                          In the event of a Fork, the User agrees that TRAZABLE may temporarily
                          suspend operations (with or without notice) and, if it deems necessary,
                          (a) configure or reconfigure its systems or (b) decide not to support (or
                          to stop supporting) a protocol and/or the original and/or the alternative
                          Cryptoasset, provided that the User has the opportunity to remove its
                          Cryptoassets from the Platform.
                        </p>
                        <p className='mt-8 text-xl text-gray-500 leading-8'>
                          Likewise, TRAZABLE will notify Users of any modifications to the protocols
                          of the Blockchains associated with the Cryptoassets and whose
                          implementation is not possible on the Platform.
                        </p>
                        <p className='mt-8 text-xl text-gray-500 leading-8'>
                          The User acknowledges and agrees that TRAZABLE assumes no responsibility
                          for any consequences in the provision of the Service that may arise in
                          connection with the modification of the Blockchain protocols.
                        </p>
                        <p className='mt-8 text-xl text-gray-500 leading-8'>
                          TRAZABLE will not assume any responsibility in the event that the User
                          provides an incorrect wallet
                        </p>
                        <p className='mt-8 text-xl text-gray-500 leading-8 font-bold'>
                          Likewise, the User acknowledges and accepts that transactions made in
                          Blockchain are irreversible once confirmed through the protocol
                          established in each case and that TRAZABLE has no ability to reverse them
                          once they have been confirmed.
                        </p>
                      </li>
                    </ol>
                  </li>

                  {/* 9 */}
                  <li>
                    <span className='font-bold uppercase text-2xl'>User Prohibitions</span>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      Without the list being exhaustive, the User undertakes to refrain from the
                      following actions, either directly or through a third party:
                      <ul className='list-disc list-inside pl-4 mt-2 mb-12'>
                        <li>
                          Use the Platform, the Services or any of its contents in a manner or for
                          purposes that TRAZABLE considers different or contrary to these Terms and
                          Conditions, laws, morals, good customs or public order.
                        </li>
                        <li>
                          Use the Platform to send, reproduce or publish files or any type of
                          information whose content is obscene, abusive, defamatory, libelous,
                          defamatory, pornographic, political or contrary to law, morals and good
                          customs, or that contains malicious software such as viruses, worms or
                          that contains any other feature capable of destroying or damaging the
                          operation of an electronic device, the Platform, or the systems or servers
                          of TRAZABLE, or a third party.
                        </li>
                        <li>
                          Use the Platform to send, reproduce or publish files in violation of
                          intellectual property rights of third parties.
                        </li>
                        <li>
                          Exploit or take advantage of an attack on the TRAZABLE, or a vulnerability
                          or a bug in the Platform, for your own benefit or that of a third party.
                        </li>
                        <li>
                          Use the Platform on behalf of or for the account of a third party (except
                          as provided in these Terms and Conditions) or allow any person other than
                          the User (including, without limitation, through assignment, license or
                          transfer) to use your account and the Services associated with it.
                        </li>
                        <li>
                          Use automatic or manual programs, software or devices to monitor or copy
                          the information or any type of content or element of the Platform.
                        </li>
                        <li>
                          Link in any way to the Platform or the Services to unsolicited promotions,
                          political campaigns or commercial messages (SPAM) or any chain letters or
                          untruthful content for personal gain or third parties.
                        </li>
                        <li>
                          Perform actions that restrict, deny or prevent third parties, access and
                          use of the Platform and the content and services offered through it.
                        </li>
                        <li>
                          Attempt to breach any authentication and security systems of the Platform.
                        </li>
                        <li>
                          Attempt to obtain any kind of material or content accessible through the
                          Platform using procedures or means other than those which, as the case may
                          be, have been expressly indicated or made available to the User.
                        </li>
                        <li>
                          Include frames, links, deep links, or links to the Platform, either from
                          other websites or from any software or electronic device.
                        </li>
                        <li>
                          Take any action that imposes a disproportionate or disproportionate burden
                          on the technological infrastructure of the Platform.
                        </li>
                        <li>
                          Use meta tags or any other type of hidden text with the name "TRAZABLE" or
                          any other name or trade name and / or intellectual property rights that
                          belong or are owned by TRAZABLE.
                        </li>
                        <li>
                          Users are not allowed to add liquidity on different decentralized
                          exchanges. Trazable reserves the right to take legal action in the case
                          that a person performs this action.
                        </li>
                      </ul>
                    </p>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      TRAZABLE reserves the right to deny or withdraw access to the Platform and/or
                      the services offered therein without prior notice to those Users who perform
                      any of the actions listed in this section.
                    </p>
                  </li>

                  {/* 10 */}
                  <li>
                    <span className='font-bold uppercase text-2xl'>
                      Account termination and closure request{' '}
                    </span>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      The User can request the cancellation of your user account through the
                      Platform, or via email to support@trazable.io only from the email address with
                      which it was registered.
                    </p>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      TRAZABLE reserves the right to request proof and/or additional information to
                      corroborate the veracity of the cancellation request and the information
                      provided by the User.
                    </p>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      The contractual relationship will not be considered terminated until TRAZABLE
                      has cancelled the account, cancelled the User from the Platform, and notified
                      the User.
                    </p>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      The document number and e-mail address associated with the User will remain
                      blocked, so that no other person will be able to create a new user account
                      using them.
                    </p>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      Transaction information will not be deleted. Regarding the retention period of
                      the User's personal data, it is permanently available in the{' '}
                      <Link to={PAGES.PRIVACY_POLICY} className='text-blue-500 underline'>
                        Privacy Policy
                      </Link>
                      .
                    </p>
                  </li>

                  {/* 11 */}
                  <li>
                    <span className='font-bold uppercase text-2xl'>
                      Security. Password recovery
                    </span>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      TRAZABLE provides the User with information about the security of the password
                      chosen.
                    </p>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      TRAZABLE is not responsible for the loss of funds resulting from theft of
                      passwords.
                    </p>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      In line with the provisions of these Terms and Conditions, TRAZABLE assumes
                      that any movements that are made in the account of a User, have been made by
                      the same, so that the User acknowledges and agrees to be solely responsible
                      for the use made of your account and the consequences and direct and indirect
                      damages arising therefrom.
                    </p>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      If the User detects that his account has been attacked or that a third party
                      has made movements through it, he must notify TRAZABLE as soon as possible to
                      proceed to block the account by sending an email to the following address:
                      support@trazable.io
                    </p>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      If the User forgets or loses his/her password, he/she can recover it by the
                      means available through the Platform or contact TRAZABLE by sending an email
                      to the following address: support@trazable.io
                    </p>
                  </li>

                  {/* 12 */}
                  <li>
                    <span className='font-bold uppercase text-2xl'>RIGHT OF WITHDRAWAL</span>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      Pursuant to Article 103 of Law 3/2014 of 27 March, the operations included in
                      these terms and conditions are excluded from the right of withdrawal.
                    </p>
                  </li>

                  {/* 13 */}
                  <li>
                    <span className='font-bold uppercase text-2xl'>Modifications of Terms</span>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      TRAZABLE may modify at any time the conditions specified here, being duly
                      published on the Platform and / or, at the sole discretion of TRAZABLE,
                      notifying the Users of the same by email.
                    </p>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      The use of the Platform by the User from the notification of the modification
                      of these Terms and Conditions shall constitute full acceptance of these Terms
                      and Conditions by the User; if the User does not agree with the new terms, the
                      User must stop using the Platform.
                    </p>
                  </li>

                  {/* 14 */}
                  <li>
                    <span className='font-bold uppercase text-2xl'>Intellectual Property</span>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      All content on the Platform, including without limitation the design of its
                      screens, promotional materials, trademarks, trade names, trade dress, text,
                      graphics, logos, images, icons, buttons, videos, sounds, music, databases,
                      source code, software and color combinations (the "Content"), is owned by
                      TRAZABLE or its licensors.
                    </p>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      The Content may not be reproduced, modified, transformed, edited, translated,
                      transferred, distributed, performed, marketed, publicly communicated, stored,
                      used for purposes other than those provided for in these Terms, or be the
                      subject of derivative works, without prior written permission from the rights
                      holder.
                    </p>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      Your access to the Platform does not grant you any license, right or title to
                      any intellectual property rights in all or any portion of the Content. Users
                      are granted only a non-exclusive, revocable, limited license to access and use
                      the Platform and the Content in accordance with these Terms and Conditions.
                    </p>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      If the User considers that the Platform or the Content violates any
                      intellectual property rights of third parties, he/she must notify TRAZABLE at
                      the address indicated in these Terms and Conditions, accompanying all
                      necessary information and documentation to support such consideration.
                    </p>
                  </li>

                  {/* 15 */}
                  <li>
                    <span className='font-bold uppercase text-2xl'>
                      DATA PROCESSING AND USE OF COOKIES
                    </span>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      In accordance with the provisions of the General Regulation on the Protection
                      of Personal Data and other applicable regulations on the subject, we inform
                      you that the personal data provided by the user through the Platform or by
                      sending emails, will be processed by TRAZABLE as responsible for processing in
                      order to carry out the registration of Users, carry out the transactions
                      requested, address the doubts of the Users, as well as receive periodic
                      notifications through electronic means. Users can find more information about
                      the processing of their data in the privacy policy, which must be read and
                      understood in order to use the Services.
                    </p>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      TRAZABLE is committed to fulfilling the obligation of secrecy of personal
                      data, so it has taken the necessary measures to prevent alteration, loss,
                      treatment or unauthorized access, taking into account at all times the state
                      of technology.
                    </p>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      On the other hand, TRAZABLE reports that by visiting its Platform (without
                      carrying out the registration process by the User) is not automatically
                      recorded any personal data that identifies a user, instead there is certain
                      non-personal information and not identifiable with a particular user that is
                      collected during the live session through devices called "cookies" that allow
                      us to 8 obtain statistical information on the use of the Platform to then be
                      able to make improvements. All Users must accept, reject or configure cookies
                      to browse the Platform through the pop-up banner when entering the Platform.
                      For more information, please see our cookies policy.
                    </p>
                  </li>

                  {/* 16 */}
                  <li>
                    <span className='font-bold uppercase text-2xl'>PRIVACY AND BLOCKCHAIN</span>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      The acquisition of Cryptoassets can be recorded on a Blockchain or public
                      blockchain. Public blockchains operate like ledgers, intended to immutably
                      record transactions on networks of computer systems. Many of these public
                      Blockchains allow for forensic analysis that can lead to de-anonymization and
                      inadvertent disclosure of private financial information, especially when
                      blockchain data is combined with other data.
                    </p>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      Because blockchains are decentralized or third-party networks that are not
                      controlled or operated by TRAZABLLE, we cannot delete, modify, or alter
                      personal data on such networks. For more information you can consult our
                      privacy policy.
                    </p>
                  </li>

                  {/* 17 */}
                  <li>
                    <span className='font-bold uppercase text-2xl'>
                      APPLICABLE LAW AND JURISDICTION
                    </span>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      For any interpretative or litigious issues related to the web portal or the
                      app, Spanish law shall apply and in case of dispute, both parties agree to
                      submit, waiving any other jurisdiction that may apply, to the jurisdiction of
                      the Courts and Tribunals of the city of Valencia (Spain) provided that such
                      dispute is not related to any economic transaction, which shall be resolved
                      through the jurisdiction of the Courts and Tribunals of the domicile of the
                      consumer or by filing the appropriate claim by filling out the following
                      electronic form:{' '}
                      <a
                        href='http://ec.europa.eu/consumers/odr/'
                        className='underline text-blue-500'
                        target='_blank'
                        rel='noreferrer'
                      >
                        http://ec.europa.eu/consumers/odr/
                      </a>
                    </p>
                  </li>

                  {/* 18 */}
                  <li>
                    <span className='font-bold uppercase text-2xl'>LINKS</span>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      In the event that there are links or hyperlinks to other Internet sites,
                      TRAZABLE will not exercise any control over such sites and content. In no case
                      TRAZABLE assume any responsibility for the contents of any link belonging to
                      another website, nor guarantee the technical availability, quality,
                      reliability, accuracy, comprehensiveness, accuracy, accuracy, validity and
                      constitutionality of any material or information contained in any such
                      hyperlinks or other Internet sites. Likewise, the inclusion of these external
                      connections does not imply any type of association, merger or participation
                      with the connected entities.
                    </p>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      It is expressly prohibited the introduction of hyperlinks for commercial
                      purposes on websites outside TRAZABLE that allow access to the Platform
                      without the express consent of TRAZABLE. In any case, the existence of
                      hyperlinks on websites outside TRAZABLE, does not imply in any case the
                      existence of commercial or mercantile relations with the owner of the website
                      where the hyperlink is established, nor the acceptance by TRAZABLE.
                    </p>
                  </li>

                  {/* 19 */}
                  <li>
                    <span className='font-bold uppercase text-2xl'>Miscellaneous</span>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      In the event that one or more clauses of these Terms and Conditions are
                      declared null and void by any competent authority or judicial body, such
                      nullity shall not affect the validity of the remaining clauses, which shall
                      remain in full force and effect.
                    </p>
                  </li>

                  {/* 20 */}
                  <li>
                    <span className='font-bold uppercase text-2xl'>Assignment</span>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      You may not assign your contractual position, this agreement, or any of your
                      rights or obligations hereunder to any third party.
                    </p>
                  </li>

                  {/* 21 */}
                  <li>
                    <span className='font-bold uppercase text-2xl'>
                      Consent to electronic notifications
                    </span>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      The User expressly consents to be notified or informed by electronic means of
                      all information related to their User status, account and use of the Platform.
                    </p>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      With your consent, we send push notifications to your mobile device to provide
                      information about changes in the price of Cryptoassets, updates to the
                      Service, promotional communications, and other related messages. You may
                      disable push notifications by changing your notification settings through your
                      device with which you access the Platform.
                    </p>
                  </li>

                  {/* 22 */}
                  <li>
                    <span className='font-bold uppercase text-2xl'>
                      contact and customer service
                    </span>
                    <p className='mt-8 text-xl text-gray-500 leading-8'>
                      TRAZABLE has a user service, from Monday to Friday from 9 to 18 hours, by
                      sending an email to the following address: info@trazable.io
                    </p>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </>
      )}
    </Layout>
  )
}

export default TermsAndConditionsPage
