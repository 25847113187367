import { Fragment, useMemo } from 'react'
import classNames from 'classnames'
import logo from '@/assets/images/logo.png'
import { Link } from '@/components/Link'
import { PAGES } from '@/routes'
import { ChevronDownIcon } from '@heroicons/react/solid'
import {
  GlobeIcon,
  MenuIcon,
  XIcon,
  GlobeAltIcon,
  CursorClickIcon,
  QrcodeIcon,
} from '@heroicons/react/outline'

import { Menu, Popover, Transition } from '@headlessui/react'

// Images
import imgToken from '@/assets/images/circulo_640_b.png'
import { useTranslation } from 'react-i18next'
import { changePathLanguage } from '@/i18n/utils'
import { languages } from '@/i18n'

export const Header = (): JSX.Element => {
  const { t } = useTranslation('nsLayout')

  const solutions = useMemo(
    () => [
      {
        name: t('nsLayout:header.solution.traceability.title'),
        description: t('nsLayout:header.solution.traceability.description'),
        to: PAGES.SOLUTIONS.TRACEABILITY,
        icon: GlobeAltIcon,
      },
      {
        name: t('nsLayout:header.solution.digitalTransformation.title'),
        description: t('nsLayout:header.solution.digitalTransformation.description'),
        to: PAGES.SOLUTIONS.DIGITAL_TRANSFORMATION,
        icon: CursorClickIcon,
      },
      {
        name: t('nsLayout:header.solution.transparency.title'),
        description: t('nsLayout:header.solution.transparency.description'),
        to: PAGES.SOLUTIONS.TRANSPARENCY,
        icon: QrcodeIcon,
      },
    ],
    [t]
  )

  return (
    <Popover className='relative bg-white shadow z-10'>
      <div className='max-w-7xl mx-auto px-4 sm:px-6'>
        <div className='flex justify-between items-center py-6 md:justify-start md:space-x-10'>
          {/* Trazable logo */}
          <div className='flex justify-start lg:w-0 lg:flex-1'>
            <Link to={PAGES.HOME}>
              <span className='sr-only'>Trazable</span>
              <img className='h-5 xl:h3 w-auto' src={logo} alt='Trazable' />
            </Link>
          </div>

          {/* Hamburger menu */}
          <div className='-mr-2 -my-2 md:hidden'>
            <Popover.Button className='bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500'>
              <span className='sr-only'>Open menu</span>
              <MenuIcon className='h-6 w-6' aria-hidden='true' />
            </Popover.Button>
          </div>

          {/* Desktop menu */}
          <Popover.Group as='nav' className='hidden md:flex space-x-10'>
            <Popover className='relative'>
              {({ open }) => (
                <>
                  <Popover.Button
                    className={classNames(
                      open ? 'text-gray-900' : 'text-gray-500',
                      'group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
                    )}
                  >
                    <span>{t('header.solutions')}</span>
                    <ChevronDownIcon
                      className={classNames(
                        open ? 'text-gray-600' : 'text-gray-400',
                        'ml-2 h-5 w-5 group-hover:text-gray-500'
                      )}
                      aria-hidden='true'
                    />
                  </Popover.Button>

                  <Transition
                    as={Fragment}
                    enter='transition ease-out duration-200'
                    enterFrom='opacity-0 translate-y-1'
                    enterTo='opacity-100 translate-y-0'
                    leave='transition ease-in duration-150'
                    leaveFrom='opacity-100 translate-y-0'
                    leaveTo='opacity-0 translate-y-1'
                  >
                    <Popover.Panel className='absolute z-10 -ml-4 mt-3 transform px-2 w-screen max-w-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2'>
                      <div className='rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden'>
                        <div className='relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8'>
                          {solutions.map(solution => (
                            <Link
                              key={solution.name}
                              to={solution.to}
                              className='-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50'
                            >
                              <solution.icon className='flex-shrink-0 h-6 w-6 text-blue-600' />
                              <div className='ml-4'>
                                <p className='text-base font-medium text-gray-900'>
                                  {solution.name}
                                </p>
                                <p className='mt-1 text-sm text-gray-500'>{solution.description}</p>
                              </div>
                            </Link>
                          ))}
                        </div>
                      </div>
                    </Popover.Panel>
                  </Transition>
                </>
              )}
            </Popover>

            <Link
              to={PAGES.ABOUT}
              className='text-base font-medium text-gray-500 hover:text-gray-900'
            >
              {t('header.about')}
            </Link>
            <a
              href={PAGES.EXTERNAL.BLOG}
              className='text-base font-medium text-gray-500 hover:text-gray-900'
            >
              {t('header.blog')}
            </a>
            <Link
              to={PAGES.PRICING}
              className='text-base font-medium text-gray-500 hover:text-gray-900'
            >
              {t('header.pricing')}
            </Link>

            <Link
              to={PAGES.TOKEN}
              className='text-base font-medium text-gray-500 hover:text-gray-900'
            >
              <b>{t('header.token')}</b>
            </Link>
          </Popover.Group>
          <Menu as='div' className='hidden md:inline-block relative text-left'>
            <div>
              <Menu.Button className='rounded-full flex items-center text-gray-500 hover:text-gray-900'>
                <span className='sr-only'>Open options</span>
                <GlobeIcon className='h-5 w-5' aria-hidden='true' />
                <ChevronDownIcon className='ml-2 h-5 w-5' aria-hidden='true' />
              </Menu.Button>
            </div>

            <Transition
              as={Fragment}
              enter='transition ease-out duration-100'
              enterFrom='transform opacity-0 scale-95'
              enterTo='transform opacity-100 scale-100'
              leave='transition ease-in duration-75'
              leaveFrom='transform opacity-100 scale-100'
              leaveTo='transform opacity-0 scale-95'
            >
              <Menu.Items className='origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none'>
                <div className='py-1'>
                  {languages.map(([lngCode, lngName]) => (
                    <Menu.Item key={lngCode}>
                      {({ active }) => (
                        <button
                          onClick={() => changePathLanguage(window.location.pathname, lngCode)}
                          className={classNames(
                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                            'block px-4 py-2 text-sm w-full text-left'
                          )}
                        >
                          {lngName}
                        </button>
                      )}
                    </Menu.Item>
                  ))}
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
          <div className='hidden md:flex items-center justify-end md:flex-1 lg:w-0'>
            <Link
              to={PAGES.CONTACT}
              className='ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-blue-600 hover:bg-blue-700'
            >
              {t('header.contact')}
            </Link>
          </div>
        </div>
      </div>

      {/* Mobile menu */}
      <Transition
        as={Fragment}
        enter='duration-200 ease-out'
        enterFrom='opacity-0 scale-95'
        enterTo='opacity-100 scale-100'
        leave='duration-100 ease-in'
        leaveFrom='opacity-100 scale-100'
        leaveTo='opacity-0 scale-95'
      >
        <Popover.Panel
          focus
          className='absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden'
        >
          <div className='rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50'>
            <div className='pt-5 pb-6 px-5'>
              <div className='flex items-center justify-between'>
                <div>
                  <img className='h-5 xl:h3 w-auto' src={logo} alt='Trazable' />
                </div>
                <div className='-mr-2'>
                  <Popover.Button className='bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500'>
                    <span className='sr-only'>Close menu</span>
                    <XIcon className='h-6 w-6' aria-hidden='true' />
                  </Popover.Button>
                </div>
              </div>
              <div className='mt-6'>
                <nav className='grid gap-y-8'>
                  <Link
                    to={PAGES.TOKEN}
                    className='-m-3 p-3 flex items-center rounded-md hover:bg-gray-50'
                  >
                    <img src={imgToken} className='h-6 w-6' alt='' />
                    <span className='ml-3 text-base font-medium text-gray-900'>
                      {t('header.token')}
                    </span>
                  </Link>
                  {solutions.map(solution => (
                    <Link
                      key={solution.name}
                      to={solution.to}
                      className='-m-3 p-3 flex items-center rounded-md hover:bg-gray-50'
                    >
                      <solution.icon
                        className='flex-shrink-0 h-6 w-6 text-blue-600'
                        aria-hidden='true'
                      />
                      <span className='ml-3 text-base font-medium text-gray-900'>
                        {solution.name}
                      </span>
                    </Link>
                  ))}
                </nav>
              </div>
            </div>
            <div className='py-6 px-5 space-y-6'>
              <div className='grid grid-cols-2 gap-y-4 gap-x-8'>
                <Link
                  to={PAGES.HOME}
                  className='text-base font-medium text-gray-900 hover:text-gray-700'
                >
                  {t('header.home')}
                </Link>

                <Link
                  to={PAGES.ABOUT}
                  className='text-base font-medium text-gray-900 hover:text-gray-700'
                >
                  {t('header.about')}
                </Link>

                <Link
                  to={PAGES.PRICING}
                  className='text-base font-medium text-gray-900 hover:text-gray-700'
                >
                  {t('header.pricing')}
                </Link>

                <Link
                  to={PAGES.SUPPORTS}
                  className='text-base font-medium text-gray-900 hover:text-gray-700'
                >
                  {t('header.supports')}
                </Link>

                <a
                  href={PAGES.EXTERNAL.BLOG}
                  className='text-base font-medium text-gray-900 hover:text-gray-700'
                >
                  {t('header.blog')}
                </a>

                <a
                  href={PAGES.EXTERNAL.HIRE}
                  className='text-base font-medium text-gray-900 hover:text-gray-700'
                >
                  {t('header.hire')}
                </a>
              </div>
              <div>
                <Link
                  to={PAGES.CONTACT}
                  className='w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-blue-600 hover:bg-blue-700'
                >
                  {t('header.contact')}
                </Link>
              </div>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  )
}
